<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="updateForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(createPlan)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="plan.name"
                      name="name"
                      rules="required"
                      label="Plan Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="plan.desc"
                      name="name"
                      rules="required"
                      label="Plan Desc."
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-if="plan.interval !== 'free'"
                      v-model="plan.price"
                      name="price"
                      rules="required|min_value:0"
                      label="Price ($)"
                      type="number"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-if="plan.interval !== 'one-time' && plan.interval !== 'free'"
                      v-model="plan.trial_period_days"
                      rules="numeric"
                      name="trial_period_days"
                      label="Trial period days"
                      type="number"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Interval</label>
                    <select v-model="plan.interval" class="form-control">
                      <option
                        v-for="interval in intervals"
                        :key="interval.key"
                        :value="interval.key"
                        >{{ interval.label }}</option
                      >
                    </select>
                  </div>
                  <div v-if="!isSalePlan && plan.interval !== 'free'" class="col-md-6">
                    <div class="form-group">
                      <label>Sale plan</label>
                      <select v-model="plan.sale_plan" class="form-control">
                        <option value="">No sale plan</option>
                        <option
                          v-for="otherPlan in plans"
                          :key="otherPlan.id"
                          :value="otherPlan.id"
                          >{{ otherPlan.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div v-if="isSalePlan" class="col-md-6">
                    <TextInput
                      v-model="plan.video"
                      name="video"
                      label="Video"
                    />
                  </div>
                </div>
                <h5 class="h5">Features</h5>
                <table class="table table-bordered meta-table">
                  <thead class="thead-light">
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <!-- <th></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in plan.metadata" :key="index">
                      <td>
                        <strong>{{item.key}}</strong>
                      </td>
                      <td>
                        <TextInput
                          v-if="item.key === 'Number of Businesses'"
                          v-model="item.value"
                          name="value"
                        />
                        <b-form-checkbox
                          v-else
                          v-model="item.value"
                          name="check-button"
                          switch
                          inline
                          class="mt-2"
                        >
                        </b-form-checkbox>
                      </td>
                    </tr>
                    <tr v-if="!isSalePlan">
                      <td>
                        <strong>Visible</strong>
                      </td>
                      <td>
                        <b-form-checkbox
                          v-model="plan.visible"
                          name="check-button"
                          switch
                          inline
                          class="mt-2"
                        >
                        </b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingCreate: false,
      intervals: [
        { key: 'day', label: 'Daily' },
        { key: 'week', label: 'Weekly' },
        { key: 'month', label: 'Monthly' },
        { key: 'year', label: 'Yearly' },
        { key: 'one-time', label: 'One-Time'},
        { key: 'free', label: 'Free'}
      ],
      plan: {
        interval: 'month',
        visible: true,
        metadata: [],
      },
      newFeature: {
        key: '',
        value: '',
      },
    }
  },
  computed: {
    plans() {
      let plans = this.$store.getters['subscription/allPlans'] || []

      return plans.filter((p) => p.is_sale_plan)
    },

    isSalePlan() {
      let salePlan = this.plan.metadata.filter((f) => f.key === 'Sales Plan')[0]
      return salePlan && salePlan.value
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }
  },

  methods: {
    createPlan() {
      this.loadingCreate = true;
      // Handle Free Plans
      if(this.plan.interval === 'free'){
        this.plan.price = 0;
      }
      this.$store
        .dispatch('subscription/createPlan', this.plan)
        .then(() => {
          this.loadingCreate = false
          this.$router.push({ name: 'admin.plans.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },

    addMetadata() {
      this.plan.metadata.push(this.newFeature)
      this.newFeature = { key: '', value: '' }
    },

    removeMetadata(index) {
      this.plan.metadata.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
  .meta-table {
    td, th {
      vertical-align: middle;
    }
    .form-group {
      margin-bottom: 0!important;
    }
  }
</style>
